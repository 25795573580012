import { Link } from 'gatsby'
import React from 'react'
import './style.scss'
import Icon from 'components/icon'

const Footer = ({ author, title }) => (
  <footer className="section footer-classic context-dark bg-image mt-3 ">
    <div className="container">
      <div className="row row-30">
        {/* <div className="col-md-4 col-xl-5">
              <div className="pr-xl-4"><a className="brand" href="index.html"><img className="brand-logo-light" src="images/agency/logo-inverse-140x37.png" alt="" width="140" height="37" srcset="images/agency/logo-retina-inverse-280x74.png 2x"/></a>
                <p>We are an award-winning creative agency, dedicated to the best result in web design, promotion, business consulting, and marketing.</p>
                
                <p className="rights"><span>©  </span><span className="copyright-year">2018</span><span> </span><span>Waves</span><span>. </span><span>All Rights Reserved.</span></p>
              </div>
            </div> */}

        <div className="col">
          <h5>Visist Us</h5>
          <Icon className="iconx" title="MapMarker" name="map-marker" library="fas"/>
          <p className="white-text">
            <a href="https://www.google.com/maps/place/37+Industry+Pl,+Bayswater+VIC+3153/@-37.8425055,145.2759371,15z/data=!4m5!3m4!1s0x6ad63b0ca869038d:0x612dd1df9c2ca5f0!8m2!3d-37.8426248!4d145.2759163">
            37 Industry Place,
            <br />
            Bayswater, Victoria, 3153
            <br />
            Australia
            </a>
          </p>
        </div>

        <div className="col">
          <h5>Contacts</h5>
          <address>
            <Icon className="iconx" title="MapMarker" name="at" library="fas" />
            <a href="mailto:info@highqflatpack.com.au">info@highqflatpack.com.au</a>
            <br />

            <Icon
              className="iconx"
              title="MapMarker"
              name="phone"
              library="fas"
            />
            <a href="tel:1300 00 47 37">1300 00 47 37</a>
          </address>
        </div>
        <div className="col">
          <h5>Follow Us</h5>
          <Icon
            className="iconx"
            title="Facebook"
            name="facebook"
            library="fab"
          />{' '}
          <a href="https://www.facebook.com/HighQFlatPack">Facebook</a>
          <Icon title="Instagram" name="instagram" library="fab" />{' '}
          <a href="#">Instagram</a>
          <br />
          <Icon title="Google" name="google" library="fab" />
          <a href="https://www.google.com/maps/place/37+Industry+Pl,+Bayswater+VIC+3153/@-37.8425055,145.2759371,15z/data=!4m5!3m4!1s0x6ad63b0ca869038d:0x612dd1df9c2ca5f0!8m2!3d-37.8426248!4d145.2759163">Google</a>
          <br />
          <Icon title="Twitter" name="twitter" library="fab" />
          <a href="#">Twitter</a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
