import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import './style.scss'

const Navi = ({ location, title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file: file(relativePath: { eq: "hqfp-logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            fixed(width: 30, height: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const [collapsed, setCollapsed] = useState(true)
  const toggleNavbar = () => setCollapsed(!collapsed)
  const classOne = collapsed
    ? 'collapse navbar-collapse'
    : 'collapse navbar-collapse show'
  const classTwo = collapsed
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'
  return (
    <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0">
      <div className="container">
        <div>
          <Link className="navbar-brand" to="/">
            <Img fixed={data.file.childImageSharp.fixed} alt="logo" />
            {title}
          </Link>
        </div>
        <button
          onClick={toggleNavbar}
          className={`${classTwo}`}
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars"></i>
        </button>

        <div className={`${classOne}`} id="navbarResponsive">
          <ul className="nav navbar-nav ml-auto">
            <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/aboutus/" className="nav-link">
                About Us
              </Link>
            </li>
            <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/under-construction/" className="nav-link">
                Shop
              </Link>
            </li>
            <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/under-construction/" className="nav-link">
                Packages
              </Link>
            </li>
            {/* <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/under-construction/" className="nav-link">
                Gallery
              </Link>
            </li> */}
            <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/order-form/" className="nav-link">
                Order Form
              </Link>
            </li>

            <li
              className={
                location.pathname === '/under-construction/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/online-designer/" className="nav-link">
               Online Designer 
              </Link>
            </li>
            <li
              className={
                location.pathname === '/contact-us/'
                  ? 'nav-item active pty-0'
                  : 'nav-item pty-0'
              }
            >
              <Link to="/contact-us/" className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navi